<script setup>
import helper from '~/resources/js/libraries/helper';
import FavoriteCard from './favorite-card.vue';

defineProps({
    listings: {
        type: Array,
        required: true,
    },
    list_view_features: {
        type: Array,
        required: true,
    },
});

const currency = ref(helper.cookies.currency());
const route = useRoute();
const router = useRouter();
const default_sale_price_direction = ref("asc");
const default_id_direction = ref("desc");

const handleSort = async ({ sort_by, sort_direction }) => {
    if (!sort_by) {
        const newQuery = { ...route.query };
        delete newQuery[`sort_by`];
        delete newQuery[`sort_direction`];
        return await router.push({
            query: newQuery,
        })
    }

    let set_sort_direction = sort_direction;
    if (route.query?.sort_by != sort_by) {
        if (!sort_direction) {
            switch (sort_by) {
                case "sale_price":
                    set_sort_direction = default_sale_price_direction.value
                    break;
                case "id":
                    set_sort_direction = default_id_direction.value
                    break;
                default:
                    set_sort_direction = "desc"
                    break;
            }
        }
    }
    else {
        set_sort_direction = route.query?.sort_direction == 'asc' ? 'desc' : 'asc'
    }

    await router.push({
        query: { ...route.query, sort_by: sort_by, sort_direction: set_sort_direction },
    })
}
</script>

<template>
    <div class="overflow-x-auto">
        <table
            class="table-auto min-w-full text-left text-sm font-light text-surface mt-4 bg-indigo-50/50 rounded overflow-hidden">
            <thead class="border-b border-neutral-200 font-medium bg-neutral-100">
                <tr class="sm:ps-0 text-gray-900 font-semibold text-sm text-left pe-4 py-4">
                    <th scope="col" class="px-2 py-4">{{ $t('client.image') }}</th>
                    <th scope="col" class="px-2 py-4">{{ $t('client.ad_title') }}</th>
                    <th scope="col" class="px-2 py-4 cursor-pointer">
                        <div @click="handleSort({ sort_by: 'sale_price' })">
                            {{ $t('client.price') }}
                        </div>
                    </th>
                    <th v-for="feature in list_view_features.filter(f => f.show_in_list_view)" scope="col"
                        class="px-2 py-4 cursor-pointer">
                        <div @click="feature.sort_in_list_view && handleSort({ sort_by: feature._id })">
                            {{ feature.name }}
                        </div>
                    </th>
                    <th scope="col" class="px-2 py-4 cursor-pointer">{{ $t('client.city_town') }}</th>
                    <th scope="col" class="px-2 py-4 cursor-pointer">
                        <div @click="handleSort({ sort_by: 'id' })">{{ $t('client.ad_date') }}</div>
                    </th>
                    <th scope="col" class="px-2 py-4"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="listing in listings"
                    class="border-b border-neutral-200 text-wrap hover:bg-indigo-50 cursor-pointer" :key="listing._id"
                    itemscope itemtype="https://schema.org/RealEstateListing">
                    <td class="whitespace-nowrap px-2 py-4 w-48">
                        <NuxtLink :to="localePath({ name: 'listings.show', params: { slug: listing.slug } })" itemprop="url">
                            <img :src="`${listing.picture}?w=630&h=550&fit=contain`" :alt="listing.title"
                                class="h-28 w-48 object-fill self-center rounded" itemprop="thumbnailUrl">
                        </NuxtLink>
                    </td>
                    <td class="whitespace-nowrap px-2 py-4 text-wrap w-64 font-normal" itemprop="name">
                        <p class="w-64 overflow-hidden h-16" :title="listing.title">
                            <NuxtLink :to="localePath({ name: 'listings.show', params: { slug: listing.slug } })" itemprop="url">
                                {{ listing.title }}
                            </NuxtLink>
                        </p>
                    </td>
                    <td class="whitespace-nowrap px-2 py-4 w-32">
                        <p class="w-32">{{ helper.formatPrice(listing.sale_price) }}
                            {{ currency.symbol }}
                        </p>
                    </td>
                    <template v-for="feature in list_view_features.filter(f => f.show_in_list_view)">
                        <td v-if="listing.features.find(f => f.slug == feature.slug)"
                            class="whitespace-nowrap px-2 py-4 w-32">
                            <p class="w-32">{{ listing.features.find(f => f.slug ==
                                feature.slug).value.label_value || listing.features.find(f => f.slug ==
                                    feature.slug).value
                                }}
                            </p>
                        </td>
                        <td v-else class="whitespace-nowrap px-2 py-4 w-32">
                            <p class="w-32">N/A</p>
                        </td>
                    </template>
                    <td class="whitespace-nowrap px-2 py-4 w-32">
                        <span :title="listing.city_town" class="w-32 flex truncate">{{ listing.city_town }}</span>
                    </td>
                    <td class="whitespace-nowrap px-2 py-4">
                        <p>{{ (listing.updated_at ? new Date(listing.updated_at) : new
                            Date(listing.created_at)).toLocaleDateString() }}</p>
                    </td>
                    <td class="px-2 py-4 w-12 text-center flex justify-center items-center" itemprop="favorite" @click.stop>
                        <div class="mt-10">
                                <favorite-card :listingId="listing._id" @click.stop></favorite-card>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>